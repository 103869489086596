import React from 'react';

import Dialog from '@mui/material/Dialog';
import {Link} from 'react-router-dom';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';





  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

function DialogsFunc(props) {

    const [show,setShow] = React.useState(false);


 

    const handleClose = () => {
        setShow(false)
      };


     return ( 
         
<Dialog fullScreen open={props.isOpen}  
PaperProps={{
  style:{
    position:'absolute',
    width:'70%',
    right:0
  }
}}

style={{width:'100%'}} onClose={props.handleClose} TransitionComponent={Transition}>
        
          <Toolbar  >
           
            <Typography variant="h6" >
              {props.title}
            </Typography>
            <IconButton edge="start" color='black' onClick={props.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
       
        
        <div style={{width:'100%',height:'100%',flexDirection:'column',backgroundColor:'rgb(240, 244, 248)',display:'flex',alignItems:'center'}}>

        {<a href={'#about'}  style={{color:'black',borderRadius:3,justifySelf:'end',padding:20,borderBottom:'1px solid lightgrey',paddingInline:10,fontSize:'20px',fontWeight:'bolder',textDecoration:'none',width:'100%'}} >About</a>}
        {<a href={'#projects'} style={{color:'black',borderRadius:3,justifySelf:'end',padding:20,borderBottom:'1px solid lightgrey',paddingInline:10,fontSize:'20px',fontWeight:'bolder',textDecoration:'none',width:'100%'}} >Project</a>}
        {<a href={'mailto:berto_654@hotmail.com.au'} style={{color:'black',borderRadius:3,justifySelf:'end',padding:20,borderBottom:'1px solid lightgrey',paddingInline:10,fontSize:'20px',fontWeight:'bolder',textDecoration:'none',width:'100%'}} >Contact Me</a>}
        {<a href={'https://roberto.developerscope.com/assets/RobertoDAmico-Frontend-Fullstack-Dev-resume-2023.pdf'} download={true} style={{color:'black',borderRadius:3,justifySelf:'end',padding:20,borderBottom:'1px solid lightgrey',paddingInline:10,fontSize:'20px',fontWeight:'bolder',textDecoration:'none',width:'100%'}} >View CV</a>}
   
          </div>
        
      </Dialog>

 
     
     )
 
 }
 
 export default DialogsFunc;