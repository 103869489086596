
import React from 'react';

import { BrowserRouter as Router, Redirect, Route,Routes } from 'react-router-dom';









import Landing from "./pages/landingpage-pages/LandingPage";







function App() {


const [auth, setAuth] = React.useState("");
const [userid,setuserId] = React.useState(0);
const [loading,setloading] = React.useState(false);
const [dataSource,setDataSource] = React.useState([]);
const [theme,setTheme] = React.useState('light');
const [name, setName] = React.useState('');
const [username, setUserName] = React.useState('');
const [profile, setProfile] = React.useState('');
const [verification, setVerification] = React.useState(0);
const [StripeAccount, setStripeAccount] = React.useState('');
const[userType,setuserType] = React.useState("");











document.addEventListener('DOMContentLoaded',function(event){
    setloading(true)


})


React.useEffect(() =>{
 
    
  
    
    
},[])

    return (
        
      
      
        
        <Router   >
         <React.Suspense fallback={<div></div>}>
             <Routess dataSource={dataSource.length > 0 ? dataSource :[]} />
         
         
         </React.Suspense>
         </Router>
         
        
        
 
      
        
        
    );
}


const Routess = ({dataSource}) =>{
  

    return(

        <Routes>
            
            
           
            <Route path={"/"} exact    element={<Landing />}  />

           
            
            
            
          


            
           
        </Routes>
    )
}





export default App;