import React from 'react'
import { Card, CardContent, CardMedia, Typography } from '@mui/material';


const Cards = ({image,title,description,link})=>{



    return(
<a href={link} target={'_blank'} style={{textDecoration:'none'}}>
<Card  sx={{ maxWidth: 345,height:460 }}

style={{cursor:'pointer',position:'relative',borderRadius:'1rem'}}
>
<CardMedia
 sx={{ height: 140 }}
 image={image}
 title=""
/>
<CardContent >
<Typography gutterBottom variant="h5" component="div"  >
  {title}
</Typography>

<Typography variant="body2" style={{borderBottom:'1px solid lightgrey',paddingBottom:10}} color="text.secondary">
{description}
</Typography>

<div style={{display:'flex',alignItems:'center',gap:5,paddingTop:10}}>
<b>FrontEnd:</b> <span style={{fontSize:14}}>React.js, HTML5, MUI, Tailwind</span>
</div>
<div style={{display:'flex',alignItems:'center',gap:5,paddingTop:10}}>
<b>Backend:</b> <span style={{fontSize:14}}>Node.js, PHP, MYSQL, RESTful API</span>
</div>
<div style={{display:'flex',alignItems:'center',gap:5,paddingTop:10}}>
<b>DevOps:</b> <span style={{fontSize:14}}>Git, CI/CD (GitHub Actions)</span>
</div>


</CardContent>


</Card>
</a>
    )
}


export default Cards;