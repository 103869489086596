import React from 'react';
import '../../css/Login.css';
import '../../css/styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';


import ScreenShot from '../../components/assets/IMG_1742.jpeg';
import ScreenDeveloperscope from '../../components/assets/DeveloperscopeFeatues1.jpg';
import ScreenEpicgamer from '../../components/assets/epicgamerspot1.png';
import ScreenMyMiix from '../../components/assets/appstore.png';
import ScreenMyMiixMusic from '../../components/assets/MusicMymiix1.png';



import DialogMenu from '../../components/Dialog/DialogFullMenu';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import Cards from '../../components/Card';
import CardsExp from '../../components/CardExperience';


function Landing() {


    const [email,setEmail] = React.useState('');
    const [password,setPassword] = React.useState('');
    const [dataSet,setData] = React.useState('');
    const [ismenu,setisMenu] = React.useState(false);
    const [randomPick,setRandomPick] = React.useState("https://embed.mymiix.com/post/638593605c1ba")
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let ref = '';
    let reff = '';
    if(urlParams.get('ref')){
      ref = '&ref='+urlParams.get('ref')
      reff = '?ref='+urlParams.get('ref')
    }else{
      ref = '';
      reff= '';
   
    }
    

    React.useEffect(()=>{

fetch('https://mymiix.com/public/api/RandomLandingPost')
.then(res=>res.json())
.then(resposeJSON=>{
  if(resposeJSON.length > 0){
    setRandomPick('https://embed.mymiix.com/post/'+resposeJSON[0].uniqueId)
  }
})

    },[])

const handleClosse = () =>{
  setisMenu(false)
}
// MyMiix is a community driven platform that welcomes all types of content creators from bloggers, vloggers, gamers, artists, photographers, and others.
    return ( 
        
      <div style={{width:'100%',flexDirection:'column',display:'block',alignItems:'center'}}>
    

    <div className={'divsss'} style={{width:'100%',position:'fixed',backgroundColor:'#508bef',zIndex:200}}>
    


   <div className={'NavDesk'} > 
   
   {<a href={'#projects'}  style={{margin:5,borderRadius:3,color:'white',padding:5,justifySelf:'end',fontSize:'95%',fontWeight:'bolder',textDecoration:'none'}} >Projects</a>}
    {<a href={'#about'}  style={{color:'white',margin:5,borderRadius:3,padding:5,justifySelf:'end',fontSize:'95%',fontWeight:'bolder',textDecoration:'none'}} >About</a>}
    </div>

    <div className={'NavMobile'} style={{backgroundColor:'#508bef',width:'100%',height:45}} >
    
    {<a href={'#projects'}  style={{margin:5,borderRadius:3,color:'white',padding:5,justifySelf:'end',fontSize:'95%',fontWeight:'bolder',textDecoration:'none'}} >Projects</a>}
    {<a href={'#about'}  style={{color:'white',margin:5,borderRadius:3,padding:5,justifySelf:'end',fontSize:'95%',fontWeight:'bolder',textDecoration:'none'}} >About</a>}
    
    </div>
   
</div>

    <div  style={{width:'100%',position:'relative',overflowY:'auto',display:'flex',alignItems:'center',justifyContent:'center',paddingTop:70,backgroundRepeat:'round', background:'linear-gradient(0deg, #5d5ce8 10%, #4e90f0 100%)'}}>
   

    
    
<div className='divContainer'  style={{justifySelf:'center',alignSelf:'center'}}>


<div style={{display:'flex',flexDirection:'column',alignItems:'center',width:'100%',padding:15}} >
  


<div className={'NavDesk'} > 
<b className={'b'}>FrontEnd Developer</b>
</div>

<div className={'NavMobile'}  >
<b className={'b'}>FrontEnd Developer</b>

</div>

<p className={'p'}>Hello, I'm <b>Roberto</b> Based In <b>Melbourne, Australia</b>. I Love Creating High Quality Web Apps That Use The Latest Tools Out There To Maximize UI And UX Quality.</p>








<div style={{display:'flex',gap:10,flexDirection:'row',alignItems:'center',flexWrap:'wrap',justifyContent:'center',marginTop:20}} >
<a href={'mailto:berto_654@hotmail.com.au'} className={'aSignup'}  style={{color:"#5d5ce8",paddingInline:0,paddingLeft:50,paddingRight:50,backgroundColor:'white',border:'0px solid white'}}  >Contact Me</a>
<a href={'https://roberto.developerscope.com/assets/RobertoDAmico-Frontend-CV-2024.pdf'} target={'_blank'} download={true} className={'aSignup'}  style={{color:"#5d5ce8",paddingInline:0,paddingLeft:50,paddingRight:50,backgroundColor:'white',border:'0px solid white'}}  >View CV</a>


</div>


<div className={'NavDesk'} > 

<div style={{display:'flex',width:'100%',gap:10,flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:70}}>


<div style={{display:'flex',width:'100%',justifyContent:'center',gap:50,flexDirection:'row',alignItems:'center'}} >

  
<b style={{color:'white',fontSize:27}}>Tech Stack |</b>
<div style={{display:'flex',alignItems:'center',gap:5}}>
<a ><img src={'https://roberto.developerscope.com/assets/img/logos/logo-2582748_960_720.png'} style={{width:40,height:40}} /></a>
<a ><img src={'https://roberto.developerscope.com/assets/img/logos/logo-2582747_1280.png'} style={{width:40,height:40}} /></a>
<a ><img src={'https://roberto.developerscope.com/assets/img/logos/JavaScript-logo.png'} style={{width:35,height:35,borderRadius:100}} /></a>
<a ><img src={'https://roberto.developerscope.com/assets/img/logos/919825.png'} style={{width:35,height:35}} /></a>
<a ><img src={'https://roberto.developerscope.com/assets/img/logos/pngimg.com - php_PNG26.png'} style={{width:40,height:40}} /></a>
<a ><img src={'https://roberto.developerscope.com/assets/img/logos/React-icon.svg.png'} style={{width:35,height:35}} /></a>

</div>




</div>



</div>

</div>


<div style={{display:'flex',flexDirection:'column',paddingTop:20}}>
<div className={'NavMobile'}  style={{width:'100%',alignItems:'center',justifyContent:'center'}} > 

<div style={{display:'flex',gap:10,flexDirection:'column',alignItems:'center'}}>


<div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',gap:10}}>

<a href={'https://www.linkedin.com/in/roberto-d-amico-b740249a/'}target={'_blank'} style={{backgroundColor:'white',color:'black',textDecoration:'none',padding:10,paddingTop:8,paddingBottom:8,borderRadius:'5rem',alignItems:'center',display:'flex',flexDirection:'column'}}><i style={{fontSize:20}} class="fa fa-linkedin" ></i></a>
<a href={'https://github.com/berto6544-collab'} target={'_blank'} style={{backgroundColor:'white',padding:7,textDecoration:'none',color:'black',paddingTop:4,paddingBottom:4,borderRadius:'5rem',alignItems:'center',display:'flex',flexDirection:'column'}}><i style={{fontSize:30}} class="fa fa-github"></i></a>

</div>

</div>

</div>

</div>



</div>





<div style={{display:'flex',width:'100%',flexDirection:'column',alignSelf:'center',alignItems:'center'}}>


<img src={ScreenShot} className={'imgScreen'} />

<div className={'NavDesk'}  style={{width:'100%',justifyContent:'center'}} > 

<div style={{display:'flex',gap:10,flexDirection:'column',alignItems:'center',marginTop:20}}>


<div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',gap:10}}>

<a href={'https://www.linkedin.com/in/roberto-d-amico-b740249a/'} target={'_blank'} style={{backgroundColor:'white',color:'black',textDecoration:'none',padding:10,paddingTop:8,paddingBottom:8,borderRadius:'5rem',alignItems:'center',display:'flex',flexDirection:'column'}}><i style={{fontSize:20}} class="fa fa-linkedin" ></i></a>
<a href={'https://github.com/berto6544-collab'} target={'_blank'} style={{backgroundColor:'white',padding:7,textDecoration:'none',color:'black',paddingTop:4,paddingBottom:4,borderRadius:'5rem',alignItems:'center',display:'flex',flexDirection:'column'}}><i style={{fontSize:30}} class="fa fa-github"></i></a>

</div>

</div>

</div>







</div>




</div>

</div>




<div className={'NavMobile'} style={{flexDirection:'column',justifyContent:'center',width:'100%',alignItems:'center',paddingTop:18,paddingLeft:18,paddingRight:20}}>
   



<div className='container' style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',justifyContent:'center',borderRadius:'0.7rem',border:'1px solid lightgrey'}}>


<b className={'bb'}>Tech Stack |</b>
    
<div  className='divContainer' style={{alignItems:'flex-end',flexDirection:'row'}}>




<div style={{display:'flex',flexDirection:'column',alignItems:'center',width:'100%'}} >
  





<div style={{display:'flex',alignItems:'center',gap:5}}>
<a ><img src={'https://roberto.developerscope.com/assets/img/logos/logo-2582748_960_720.png'} style={{width:40,height:40}} /></a>
<a ><img src={'https://roberto.developerscope.com/assets/img/logos/logo-2582747_1280.png'} style={{width:40,height:40}} /></a>
<a ><img src={'https://roberto.developerscope.com/assets/img/logos/JavaScript-logo.png'} style={{width:35,height:35,borderRadius:100}} /></a>
<a ><img src={'https://roberto.developerscope.com/assets/img/logos/919825.png'} style={{width:35,height:35}} /></a>
<a ><img src={'https://roberto.developerscope.com/assets/img/logos/pngimg.com - php_PNG26.png'} style={{width:40,height:40}} /></a>
<a ><img src={'https://roberto.developerscope.com/assets/img/logos/React-icon.svg.png'} style={{width:35,height:35}} /></a>

</div>




</div>




</div>




</div>


</div>



<div className='container' style={{position:'relative'}}>
   

    
    
<div id={'about'} className='divContainerr' style={{justifyContent:'center',width:'100%',paddingTop:40,paddingLeft:5,paddingRight:5}} >




<div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',borderRadius:'0.7rem',padding:15,border:'1px solid lightgrey'}} >
  




<b className={'bb'}>About Me</b>


<div style={{display:'flex',alignItems:'center',gap:20,paddingBottom:20,justifyContent:'center',flexWrap:'wrap',width:'100%'}}>
{/*<CardsExp

title={"Fullstack Development"}
description={"3+ years Frontend Development using React.JS, Node.js, HTML, CSS, Tailwind, Bootstrap, MUI, Express.js, PHP, peer.js, & Socket.io"}
/>*/}




</div>


<p className={'pp'}>I'm a <b>self-taught</b> <b>frontend</b> developer who enjoys bringing creative ideas to life.<br/>I'm big on <b>problem-solving</b> & enjoys the process of building <b>websites</b>, <b>games</b> & <b>apps</b>.</p>





</div>







</div>

</div>





<div  style={{position:'relative',width:'100%'}}>
   

    
    
<div id={'projects'} className='divContainer' >




<div style={{display:'flex',flexDirection:'column',alignItems:'center',width:'100%',padding:15}} >
  




<b className={'bb'}>Projects</b>






</div>




</div>

</div>


<div style={{width:'100%',position:'relative'}}>
   


    
<div className='divContainerr' style={{flexWrap:'wrap',gap:20,justifyContent:'center',padding:10,display:'flex',width:'100%',alignItems:'center'}}>


<Cards  
image={ScreenDeveloperscope}
title={"Bring Your Ideas To Life On Developerscope."}
description={"Oversee your team, provide feedback, organize campaigns, handle analytics, and showcase your startup to technical, business, and marketing cofounders all-in-one."}
link={'https://developerscope.com'}
/>


<Cards  
image={ScreenEpicgamer}
title={"Find, Play & Host Games"}
description={"GamersSpark connect's players with game hosts for a wide variety of games, ranging from RPG, ARPG, FPS, TPS, to MOBA games. Discover and play games that fit seamlessly into your schedule."}
link={'https://gamersspark.com'}
/>

<Cards  
image={ScreenMyMiix}
title={"Connect, Create, Post & Broadcast on MyMiix."}
description={"MyMiix is the easiest image, video, audio & text sharing platform. Creators share music, podcasts, vlogs, blogs, artwork, photos, gaming & more."}
link={'https://mymiix.com'}
/>

<Cards  
image={ScreenMyMiixMusic}
title={"MyMiix Music Your All-In-One Music Streaming Platform."}
description={"Upload music from youtube, spotify, soundcloud & mymiix music."}
link={'https://music.mymiix.com'}
/>


</div>








</div>




<div style={{width:'100%',position:'relative',paddingTop:40}}>
   

    
    
<div className='divContainerr' >




<div style={{display:'flex',flexDirection:'column',alignItems:'center',width:'100%',padding:15}} >
  




<b className={'bb'}>Why I Started Developerscope</b>






</div>




</div>

</div>


<div style={{width:'100%',position:'relative',paddingBottom:80}}>
   


    
<div className='divContainer' style={{flexWrap:'wrap',gap:10,justifyContent:'center',paddingBottom:30,padding:10,paddingRight:40,paddingLeft:40,flexDirection:'column',display:'flex',width:'100%',alignItems:'center'}}>


<p style={{textAlign:'center'}}>While i was working on my first project and really wanted to find a co-founder. I tried hopping onto forums like <b>YC Co‑Founder Matching</b>, <b>Reddit</b>, <b>CoFoundersLab</b>, <b>LinkedIn</b>, and <b>Indie Hackers</b>. But, you know what? It wasn't just me struggling with this.
That's when Developerscope came to life. I wanted to make the whole co-founder search easier. It's not meant to feel like some Tinder match-making platform—it's about creating real connections between founders and potential co-founders.
Even though those other forums are cool and all, I just think Developerscope has this potential to bring co-founders together in a way that feels more natural and straightforward.

</p>



</div>








</div>






    
    



<DialogMenu isOpen={ismenu} username={null} handleClose={handleClosse} title={''}  />
 
    </div>
    
    )
}

export default Landing;